:root {
  --White: #fff;
  --White-10: rgba(16, 102, 223, 0);
  --River-bed: #4b5563;
  --Grey: #686e84;
  --Black: #000;
  --Blue: #1066df;
  --Light-blue: #10badf;
  --Solitude: #ebeef4;
  --Alice-Blue: #f5f7fa;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif !important;
  color: var(--Black);
}
body {
  height: 100vh;
  overflow-x: hidden;
}

a {
  text-decoration: none !important;
}

ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

/* Hide Arrows From Input Number  */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Hide Arrows From Input Number */
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
